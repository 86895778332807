var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orderdetail-container position-relative" },
    [
      _c("div", { staticClass: "auto-table-flex" }, [
        _c("div", { staticClass: "text-center sale-font mb-25" }, [
          _vm._v(" 订单号：" + _vm._s(_vm.merchantList.order_no) + " "),
        ]),
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "addForm",
                attrs: {
                  "label-width": "150px",
                  model: _vm.merchantList,
                  inline: true,
                  "hide-required-asterisk": true,
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "客户姓名", prop: "title" } },
                              [
                                _c("div", [
                                  _vm.merchantList.member
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.merchantList.member.fullname
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("—")]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "客户电话", prop: "storeType" },
                              },
                              [
                                _c("div", [
                                  _vm.merchantList.member
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.merchantList.member.mobile)
                                        ),
                                      ])
                                    : _c("span", [_vm._v("—")]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "城市仓", prop: "warehouseId" },
                              },
                              [
                                _c("div", [
                                  _vm.merchantList.store_delivery &&
                                  _vm.merchantList.store_delivery.logistics
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.merchantList.store_delivery
                                              .logistics.name
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("—")]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "自提点信息",
                                  prop: "carLicense",
                                },
                              },
                              [
                                _c("div", [
                                  _vm.merchantList.store_delivery
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.merchantList.store_delivery.name
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("—")]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "客户地址",
                                  prop: "carLicense",
                                },
                              },
                              [
                                _c("div", [
                                  _vm.merchantList.member
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(
                                              _vm.merchantList.member.address
                                            )
                                          )
                                        ),
                                      ])
                                    : _c("span", [_vm._v("—")]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-30" },
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loading,
                                expression: "loading",
                              },
                            ],
                            ref: "goodsTable",
                            attrs: {
                              data: _vm.goodsData,
                              border: true,
                              "show-summary": true,
                              "summary-method": _vm.goodsCount,
                              "header-cell-style": {
                                color: "#333333",
                                background: "#EFF6FF",
                                "text-align": "center",
                              },
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                align: "center",
                                width: "80",
                                label: "序号",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "goodsName",
                                align: "center",
                                label: "主图",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return scope.row.product
                                        ? [
                                            _c("el-image", {
                                              staticClass: "kv pointer",
                                              attrs: {
                                                src: scope.row.product.kv,
                                                "preview-src-list": [
                                                  scope.row.product.kv,
                                                ],
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        : undefined
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "title",
                                align: "left",
                                width: "250",
                                label: "商品信息",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.product
                                        ? _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mb-15",
                                                staticStyle: {
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                scope.row.product.title
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.product
                                                            .title
                                                        )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                scope.row.product.spec
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "#" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .spec
                                                          )
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                scope.row.product.sku_code
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "(" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .sku_code
                                                          ) +
                                                          ")"
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mb text-light" },
                                              [
                                                _vm._v(
                                                  " 等级/规格：" +
                                                    _vm._s(
                                                      _vm.$empty.empty(
                                                        scope.row.product.grade
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(
                                                        _vm.unitTypeList.find(
                                                          (child) =>
                                                            child.value ===
                                                            scope.row.product
                                                              .unit_type
                                                        )?.label
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c("div", { staticClass: "mb" }, [
                                              _vm._v(
                                                " 毛重/毛单价：" +
                                                  _vm._s(
                                                    _vm.$empty.empty(
                                                      scope.row.gross_weight
                                                    )
                                                  ) +
                                                  "【" +
                                                  _vm._s(
                                                    _vm.$empty.empty(
                                                      scope.row
                                                        .gross_weight_price
                                                    )
                                                  ) +
                                                  "元/斤】 "
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-15 d-flex a-center",
                                              },
                                              [
                                                _vm._v(
                                                  " 净重/净单价：" +
                                                    _vm._s(
                                                      _vm.$empty.empty(
                                                        scope.row.weight
                                                      )
                                                    ) +
                                                    "【" +
                                                    _vm._s(
                                                      _vm.$empty.empty(
                                                        scope.row.weight_price
                                                      )
                                                    ) +
                                                    "元/斤】 "
                                                ),
                                                scope?.row?.weight_diff
                                                  ? _c("img", {
                                                      staticStyle: {
                                                        width: "14px",
                                                        height: "14px",
                                                      },
                                                      attrs: {
                                                        alt: "",
                                                        src: require("@/assets/image/tip.png"),
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onHandleWeight(
                                                            scope?.row
                                                              ?.weight_diff
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _c("div", [
                                              scope.row.product.supplier ||
                                              scope.row.product.buyer
                                                ? _c("div", [
                                                    _c("span", [
                                                      _vm._v(
                                                        "主供货商：" +
                                                          _vm._s(
                                                            scope.row.product
                                                              .supplier.title
                                                          )
                                                      ),
                                                    ]),
                                                    scope.row.product.buyer
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "(" +
                                                              _vm._s(
                                                                scope.row
                                                                  .product.buyer
                                                                  .fullname
                                                              ) +
                                                              ")"
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ])
                                                : _c("div", [_vm._v("—")]),
                                            ]),
                                          ])
                                        : _c("div", [_vm._v("—")]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "qty",
                                align: "center",
                                label: "采购数量",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$empty.empty(scope.row.qty)
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "original_price",
                                align: "center",
                                label: "开市价(元)",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "price",
                                align: "center",
                                label: "成交单价(元)",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "membership_price",
                                align: "center",
                                label: "会员价(元)",
                                "min-width": "100",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "is_vip",
                                align: "center",
                                label: "是否会员商品",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          Object.values(
                                            _vm.MEMBER_VIP_STATUS
                                          ).find(
                                            (item) =>
                                              scope.row.is_vip ===
                                              (item.value ||
                                                _vm.MEMBER_VIP_STATUS.no.value)
                                          ).label
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "is_vip_payment",
                                align: "center",
                                label: "是否会员价购买",
                                "min-width": "110",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          Object.values(
                                            _vm.MEMBER_VIP_STATUS
                                          ).find(
                                            (item) =>
                                              scope.row.is_vip_payment ===
                                              (item.value ||
                                                _vm.MEMBER_VIP_STATUS.no.value)
                                          ).label
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "product_amt",
                                align: "center",
                                label: "商品总额",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "purchase_amt",
                                align: "center",
                                label: "服务费(元)",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "freight_amt",
                                align: "center",
                                label: "运费(元)",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "finance_amt",
                                width: "120",
                                align: "center",
                                label: "金融手续费(元)",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "ori_total_amt",
                                align: "center",
                                label: "总金额(元)",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "total_amt",
                                align: "center",
                                label: "支付总金额",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "real_diff_total_amt",
                                align: "center",
                                label: "退差价总金额",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              scope.row.real_diff_total_amt
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "400",
                                            trigger: "hover",
                                            "open-delay": 600,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "diff-wrap" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "title" },
                                                [_vm._v("退差明细")]
                                              ),
                                              _vm._l(
                                                scope.row.order_price_diffs,
                                                function (child, index) {
                                                  return _c(
                                                    "div",
                                                    { staticClass: "child" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "first",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                `${
                                                                  index + 1
                                                                }. ${_vm.diffsDetails(
                                                                  scope.row,
                                                                  child
                                                                )}`
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "second",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                `退款时间: ${
                                                                  child?.create_time ||
                                                                  "-"
                                                                }`
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          scope.row.real_diff_total_amt &&
                                          scope.row.real_diff_total_amt !==
                                            "0.00"
                                            ? _c("i", {
                                                staticClass: "el-icon-info",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "discount_amount",
                                align: "center",
                                label: "优惠券金额",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "actual_payment",
                                align: "center",
                                label: "订货状态",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.supplier_status == 1
                                        ? _c("span", [_vm._v("未订货")])
                                        : scope.row.supplier_status == 2
                                        ? _c("span", [_vm._v("已订货")])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                fixed: "right",
                                prop: "actual_payment",
                                width: "150",
                                align: "center",
                                label: "供货单号",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.supplier_order
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.supplier_order
                                                  .supplier_order_no
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单创建时间",
                                  prop: "create_time",
                                },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$empty.empty(
                                        _vm.merchantList.create_time
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单支付时间",
                                  prop: "spay_attoreType",
                                },
                              },
                              [
                                _vm.merchantList.pay_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.pay_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单取消时间",
                                  prop: "cancel_at",
                                },
                              },
                              [
                                _vm.merchantList.cancel_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.cancel_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "订单完成时间",
                                  prop: "receive_at",
                                },
                              },
                              [
                                _vm.merchantList.receive_at
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$empty.time(
                                              _vm.merchantList.receive_at
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("div", [_vm._v("—")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      !_vm.hideFooter
        ? _c(
            "div",
            { staticClass: "fy1" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.back()
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        staticClass: "shadow",
        on: {
          click: function ($event) {
            _vm.showImage = false
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImage,
              expression: "showImage",
            },
          ],
          staticClass: "bigImage",
        },
        [
          _c("img", { attrs: { src: _vm.bigImage, alt: "" } }),
          _c("i", {
            staticClass: "el-icon el-icon-close bigClose",
            on: {
              click: function ($event) {
                _vm.showImage = false
              },
            },
          }),
        ]
      ),
      _c("fix-weight-log", { ref: "refWeightLog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }