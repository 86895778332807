<template>
  <div class="orderdetail-container position-relative">
    <div class="auto-table-flex">
      <div class="text-center sale-font mb-25">
        订单号：{{ merchantList.order_no }}
      </div>
      <div>
        <el-form
          label-width="150px"
          :model="merchantList"
          ref="addForm"
          :inline="true"
          :hide-required-asterisk="true"
        >
          <div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="客户姓名" prop="title">
                  <div>
                    <span v-if="merchantList.member">{{
                      merchantList.member.fullname
                    }}</span>
                    <span v-else>—</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户电话" prop="storeType">
                  <div>
                    <span v-if="merchantList.member">{{
                      merchantList.member.mobile
                    }}</span>
                    <span v-else>—</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="城市仓" prop="warehouseId">
                  <div>
                    <span
                      v-if="
                        merchantList.store_delivery &&
                        merchantList.store_delivery.logistics
                      "
                      >{{ merchantList.store_delivery.logistics.name }}</span
                    >
                    <span v-else>—</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="自提点信息" prop="carLicense">
                  <div>
                    <span v-if="merchantList.store_delivery">{{
                      merchantList.store_delivery.name
                    }}</span>
                    <span v-else>—</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="客户地址" prop="carLicense">
                  <div>
                    <span v-if="merchantList.member">{{
                      $empty.empty(merchantList.member.address)
                    }}</span>
                    <span v-else>—</span>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="mb-30">
              <el-table
                :data="goodsData"
                v-loading="loading"
                :border="true"
                :show-summary="true"
                ref="goodsTable"
                :summary-method="goodsCount"
                :header-cell-style="{
                  color: '#333333',
                  background: '#EFF6FF',
                  'text-align': 'center',
                }"
              >
                <el-table-column
                  type="index"
                  align="center"
                  width="80"
                  label="序号"
                >
                </el-table-column>
                <el-table-column prop="goodsName" align="center" label="主图">
                  <template slot-scope="scope" v-if="scope.row.product">
                    <el-image
                      :src="scope.row.product.kv"
                      :preview-src-list="[scope.row.product.kv]"
                      class="kv pointer"
                      alt=""
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  align="left"
                  width="250"
                  label="商品信息"
                >
                  <template slot-scope="scope">
                    <div v-if="scope.row.product">
                      <div class="mb-15" style="font-weight: bold">
                        <span v-if="scope.row.product.title">{{
                          scope.row.product.title
                        }}</span>
                        <span v-if="scope.row.product.spec"
                          >#{{ scope.row.product.spec }}</span
                        ><span v-if="scope.row.product.sku_code"
                          >({{ scope.row.product.sku_code }})</span
                        >
                      </div>
                      <div class="mb text-light">
                        等级/规格：{{ $empty.empty(scope.row.product.grade) }}
                        <span
                          >/{{
                            unitTypeList.find(
                              (child) =>
                                child.value === scope.row.product.unit_type
                            )?.label
                          }}</span
                        >
                        <!-- <span v-if="scope.row.product.unit_type == 1">/件</span>
                        <span v-if="scope.row.product.unit_type == 2">/箱</span>
                        <span v-if="scope.row.product.unit_type == 3"
                          >/袋子</span
                        > -->
                      </div>
                      <div class="mb">
                        毛重/毛单价：{{
                          $empty.empty(scope.row.gross_weight)
                        }}【{{
                          $empty.empty(scope.row.gross_weight_price)
                        }}元/斤】
                      </div>
                      <div class="mb-15 d-flex a-center">
                        净重/净单价：{{ $empty.empty(scope.row.weight) }}【{{
                          $empty.empty(scope.row.weight_price)
                        }}元/斤】
                        <img
                          v-if="scope?.row?.weight_diff"
                          @click="onHandleWeight(scope?.row?.weight_diff)"
                          alt=""
                          src="@/assets/image/tip.png"
                          style="width: 14px; height: 14px"
                        />
                      </div>
                      <div>
                        <div
                          v-if="
                            scope.row.product.supplier ||
                            scope.row.product.buyer
                          "
                        >
                          <span
                            >主供货商：{{
                              scope.row.product.supplier.title
                            }}</span
                          >
                          <span v-if="scope.row.product.buyer"
                            >({{ scope.row.product.buyer.fullname }})</span
                          >
                        </div>
                        <div v-else>—</div>
                      </div>
                    </div>
                    <div v-else>—</div>
                  </template>
                </el-table-column>
                <el-table-column prop="qty" align="center" label="采购数量">
                  <template slot-scope="scope">
                    <span>{{ $empty.empty(scope.row.qty) }}</span>
                  </template>
                </el-table-column>

                <!-- 会员改版需求 start -->
                <el-table-column
                  prop="original_price"
                  align="center"
                  label="开市价(元)"
                  min-width="100"
                >
                </el-table-column>
                <!-- 商品改版新增 start -->
                <el-table-column
                  prop="price"
                  align="center"
                  label="成交单价(元)"
                  min-width="100"
                >
                </el-table-column>
                <!-- 商品改版新增 end -->
                <el-table-column
                  prop="membership_price"
                  align="center"
                  label="会员价(元)"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="is_vip"
                  align="center"
                  label="是否会员商品"
                  min-width="100"
                >
                  <template slot-scope="scope">{{
                    Object.values(MEMBER_VIP_STATUS).find(
                      (item) =>
                        scope.row.is_vip ===
                        (item.value || MEMBER_VIP_STATUS.no.value)
                    ).label
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="is_vip_payment"
                  align="center"
                  label="是否会员价购买"
                  min-width="110"
                >
                  <template slot-scope="scope">{{
                    Object.values(MEMBER_VIP_STATUS).find(
                      (item) =>
                        scope.row.is_vip_payment ===
                        (item.value || MEMBER_VIP_STATUS.no.value)
                    ).label
                  }}</template>
                </el-table-column>
                <!-- 会员改版需求 end -->

                <el-table-column
                  prop="product_amt"
                  align="center"
                  label="商品总额"
                >
                </el-table-column>
                <el-table-column
                  prop="purchase_amt"
                  align="center"
                  label="服务费(元)"
                >
                </el-table-column>
                <el-table-column
                  prop="freight_amt"
                  align="center"
                  label="运费(元)"
                >
                </el-table-column>
                <el-table-column
                  prop="finance_amt"
                  width="120"
                  align="center"
                  label="金融手续费(元)"
                >
                </el-table-column>
                <el-table-column
                  prop="ori_total_amt"
                  align="center"
                  label="总金额(元)"
                >
                </el-table-column>

                <!-- 商品改版新增 start -->
                <el-table-column
                  prop="total_amt"
                  align="center"
                  label="支付总金额"
                >
                </el-table-column>
                <el-table-column
                  prop="real_diff_total_amt"
                  align="center"
                  label="退差价总金额"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <span>
                      {{ scope.row.real_diff_total_amt }}
                    </span>
                    <!-- 悬浮信息框 start -->
                    <el-popover
                      placement="bottom"
                      width="400"
                      trigger="hover"
                      :open-delay="600"
                    >
                      <div class="diff-wrap">
                        <div class="title">退差明细</div>
                        <div
                          v-for="(child, index) of scope.row.order_price_diffs"
                          class="child"
                        >
                          <div class="first">
                            {{
                              `${index + 1}. ${diffsDetails(scope.row, child)}`
                            }}
                          </div>
                          <div class="second">
                            {{ `退款时间: ${child?.create_time || "-"}` }}
                          </div>
                        </div>
                      </div>
                      <i
                        v-if="
                          scope.row.real_diff_total_amt &&
                          scope.row.real_diff_total_amt !== '0.00'
                        "
                        slot="reference"
                        class="el-icon-info"
                      ></i>
                    </el-popover>
                    <!-- 悬浮信息框 end -->
                  </template>
                </el-table-column>
                <!-- 商品改版新增 end -->
                <el-table-column
                  prop="discount_amount"
                  align="center"
                  label="优惠券金额"
                >
                </el-table-column>
                <el-table-column
                  prop="actual_payment"
                  align="center"
                  label="订货状态"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.supplier_status == 1">未订货</span>
                    <span v-else-if="scope.row.supplier_status == 2"
                      >已订货</span
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  prop="actual_payment"
                  width="150"
                  align="center"
                  label="供货单号"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.supplier_order">{{
                      scope.row.supplier_order.supplier_order_no
                    }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="订单创建时间" prop="create_time">
                  <div>{{ $empty.empty(merchantList.create_time) }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单支付时间" prop="spay_attoreType">
                  <div v-if="merchantList.pay_at">
                    {{ $empty.time(merchantList.pay_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="订单取消时间" prop="cancel_at">
                  <div v-if="merchantList.cancel_at">
                    {{ $empty.time(merchantList.cancel_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="订单完成时间" prop="receive_at">
                  <div v-if="merchantList.receive_at">
                    {{ $empty.time(merchantList.receive_at) }}
                  </div>
                  <div v-else>—</div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>

    <div class="fy1" v-if="!hideFooter">
      <el-button @click="$router.back()">返回</el-button>
    </div>
    <div v-show="showImage" class="shadow" @click="showImage = false"></div>
    <div v-show="showImage" class="bigImage">
      <img :src="bigImage" alt="" />
      <i class="el-icon el-icon-close bigClose" @click="showImage = false"></i>
    </div>
    <!-- 修改重量记录 start -->
    <fix-weight-log ref="refWeightLog"></fix-weight-log>
    <!-- 修改重量记录 end -->
  </div>
</template>

<script>
import { MEMBER_VIP_STATUS, UNIT_TYPE_ENUM } from "./utils/enum/index";
import fixWeightLog from "./modules/fix-weight-log/index";

export default {
  components: {
    fixWeightLog,
  },
  data() {
    return {
      merchantList: {},
      id: "",
      bigImage: "",
      showImage: false,
      goodsData: [], // 商品信息
      MEMBER_VIP_STATUS,
      unitTypeList: Object.values(UNIT_TYPE_ENUM), // 单位列
      loading: false,
    };
  },
  computed: {
    /**
     * 是否隐藏底部按钮
     */
    hideFooter() {
      return this.$route.query?.hide;
    },
    /**
     * 商品退款信息
     */
    diffsDetails() {
      return (data, child) => {
        const { title, spec, sku_code, unit_type } = data?.product;
        const { present_price, real_difference_price } = child;
        const unit = this.unitTypeList.find(
          (item) => item.value === unit_type
        )?.label;
        const name = title + spec + "(" + sku_code + ") ";
        const present = " 更改售价￥" + present_price;
        const difference = " 退差价￥" + real_difference_price;
        const res = name + data.qty + unit + present + difference;
        return res;
      };
    },
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
  },
  methods: {
    // 修改毛重 净重
    onHandleWeight(data) {
      this.$refs.refWeightLog.onInitData({ ...data, isCount: false });
    },
    // 获取详情
    getDetail() {
      this.$api.general.orderDetail({ id: this.id }).then((res) => {
        this.merchantList = res.data;
        this.goodsData = res.data.order_detail;
      });
    },
    getGlass(src) {
      this.bigImage = src;
      this.showImage = true;
    },
    // 商品信息合计方法
    goodsCount(param) {
      const { columns, data } = param;
      const index = 9;
      console.log(data);
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = "合计";
          return;
        }
      });
      sums["3"] = this.merchantList.number;
      sums[index] = this.merchantList.product_amt + "元";
      sums[index + 1] = this.merchantList.purchase_amt + "元";
      sums[index + 2] = this.merchantList.freight_amt + "元";
      sums[index + 3] = this.merchantList.finance_amt + "元";
      sums[index + 5] = this.merchantList.order_amt + "元";
      return sums;
    },
  },
};
</script>

<style lang="less">
.orderdetail-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;

  .el-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .auto-table-flex {
    overflow-y: auto;
  }

  .kv {
    width: 100px;
    height: 100px;
  }

  .sale-font {
    font-size: 15px;
  }

  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  // .auto-table-flex .el-table .el-table__footer-wrapper .cell {
  //   color: #E65A34;
  // }
}
/** 退差价提示框 */
.diff-wrap {
  .title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 550;
  }
  .child {
    line-height: 25px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    &:not(:first-child) {
      margin-bottom: 10px;
    }
  }
}
</style>
